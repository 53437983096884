import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./navbar.css";
import React, { useState } from 'react';

function Navbar() {
  const navRef = useRef();
  const [isTramitesOpen, setIsTramitesOpen] = useState(false);
  const [isMatriculacionOpen, setIsMatriculacionOpen] = useState(false);
  const [isHabilitacionOpen, setIsHabilitacionOpen] = useState(false);
  const [isProfesionalesOpen, setIsProfesionalesOpen] = useState(false);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const closeTramites = () => {
    setIsTramitesOpen(false);
  };

  const instructivoMatriculacionClick = () => {
    window.open('https://drive.google.com/file/d/1-2fne9ZdRy7kZjpXhA3JUFdJulXJoTV6/view?usp=sharing', '_blank');
  };

  const reglamentoHabilitacionClick = () => {
    window.open('https://drive.google.com/file/d/1-0svds6GsVPqPRmv_t6nJ4tSakXcReAD/view?usp=sharing', '_blank');
  };

  const solicitudHabilitacionClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSeOaSs8_d45yoURVCDY7sDYfW5ZEsEO77BsrWX6RLMwc1u4Ew/viewform', '_blank');
  };

  const formularioMatriculacionClick = () => {
    window.open('https://drive.google.com/file/d/1-L3UoJ5pn3gacNeI5wNt7bVO-sl2TgVa/view?usp=sharing', '_blank');
  };

  const arancelesClick = () => {
    window.open('https://drive.google.com/file/d/1-QRPxtdx6KX7veHOb9zyqtJU4tUvAz7o/view?usp=sharing', '_blank');
  };

  const smoothScrollTo = (targetId) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <header>
      <h3>Colegio de Fonoaudiólogos Misiones</h3>
      <nav ref={navRef}>
        <a onClick={() => smoothScrollTo('inicio')} onMouseDown={closeTramites}>Inicio</a>
        <a onClick={() => smoothScrollTo('institucional')} onMouseDown={closeTramites}>Institucional</a>

        {/* Dropdown de Profesionales */}
        <div 
          className='dropdown' 
          onMouseEnter={() => setIsProfesionalesOpen(true)} 
          onMouseLeave={() => setIsProfesionalesOpen(false)}
        >
          <a className='aProfesionales'>Profesionales</a>
          {isProfesionalesOpen && (
            <ul className="menu-vertical">
              <li onClick={() => smoothScrollTo('profesionales')} >Listado de Profesionales</li>
              <li  onClick={() => smoothScrollTo('profesionales')}>Aranceles Profesionales</li>
            </ul>
          )}
        </div>

        <a onClick={() => smoothScrollTo('contacto')} onMouseDown={closeTramites}>Contacto</a>

  

        {/* Dropdown de Trámites */}
        <div 
          className='dropdown' 
          onMouseEnter={() => setIsTramitesOpen(true)} 
          onMouseLeave={() => setIsTramitesOpen(false)}
        >
          <a className='aTramites'>Trámites</a>
          {isTramitesOpen && (
            <ul className="menu-vertical">
              <li onClick={() => {setIsMatriculacionOpen(!isMatriculacionOpen);setIsHabilitacionOpen(false)}} className="li-titulo">Matriculación</li>
              {isMatriculacionOpen && (
                <ul>
                  <li className="subtitulo" onClick={instructivoMatriculacionClick}>-Instructivo</li>
                  <li className="subtitulo" onClick={formularioMatriculacionClick}>-Formulario</li>
                </ul>
              )}
              <li  onClick={() =>{setIsHabilitacionOpen(!isHabilitacionOpen);setIsMatriculacionOpen(false)} } className="li-titulo">Habilitación</li>
              {isHabilitacionOpen && (
                <ul>
                  <li className="subtitulo" onClick={reglamentoHabilitacionClick}>-Reglamento</li>
                  <li className="subtitulo" onClick={solicitudHabilitacionClick}>-Solicitud</li>
                </ul>
              )}
              <li onClick={arancelesClick}>Aranceles</li>
            </ul>
          )}
        </div>

        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>

      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
