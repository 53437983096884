import React from 'react';
import './profesionales.css';  // Importa el archivo CSS

const Profesionales = () => {
  const handleBuscarProfesionales = () => {
    window.open('https://drive.google.com/file/d/1JkaK6v3zLzNSTCkgE-YqUHYZVGgZuhtk/view?usp=drive_link', '_blank');
  };
  const handleAranceles = () => {
    window.open('https://drive.google.com/file/d/1v8CYoVTY_r2ZPH1obbRXfPNYkCY31BOz/view?usp=sharing', '_blank');
  };

  return (
<>
    <div id='profesionales'></div>
    <div className="profesionales-container" > {/* Aplica la clase CSS */}
      <div className="overlay"></div> {/* Capa de color transparente */}
      <div className="content">
        <h2 className='titulo'>Profesionales</h2>
       <div><p className='pProfesionales'></p></div>
       <div>
       <button onClick={handleBuscarProfesionales} className='btn-texto'>Listado de profesionales</button>
       </div>
       <br></br>
  <div>
  <button onClick={handleAranceles} className='btn-texto'>Aranceles profesionales - Mínimos éticos sugeridos</button>
  </div>
        

      </div>
    </div>
    </>
  );
};

export default Profesionales;

